/* eslint-disable no-template-curly-in-string */
import * as Sentry from '@sentry/nextjs';
import query from 'query-string';
import axios from './axios';
import { API_HOST } from './app.config';
import { isArray, isObject } from '../helpers/javascript';
import { create } from './team.services';
import { gtmFireEvent } from '../helpers/gtm';
import translate from '../data/translations';
import { mailExistProviderError } from '../helpers/auth';
// eslint-disable-next-line import/no-cycle
import { plans } from './stripe.services';

export const logged = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (error) {}
  return null;
};
export const getGoogleSheetToken = () => {
  try {
    return JSON.parse(localStorage.getItem('google-sheet-token'));
  } catch (error) {}
  return null;
};

// Get header for api
export const getAuthHeader = () => {
  const user = logged();
  return {
    Authorization: `Bearer ${user ? user.jwt : process.env.API_DEFAULT_TOKEN}`,
    'X-Mission-Control-Team': user ? user.team : process.env.API_DEFAULT_TEAM
  };
};

export const logout = () =>
  new Promise(resolve => {
    localStorage.removeItem('user');
    localStorage.removeItem('woxo-current-widget');
    if (window.Woxo && window.Woxo.Suscription && window.Woxo.Suscription.plan)
      window.Woxo.Suscription.plan = false;

    gtmFireEvent({
      event: 'onSecurity',
      category: 'security',
      action: 'logout',
      label: 'user logout action'
    });

    gtmFireEvent({
      event: 'userLogout'
    });
    resolve();
  });

export const setProfile = (
  { name, username, email, password, rePassword, profession, entityType },
  headers
) =>
  new Promise((resolve, reject) => {
    const audience = localStorage.getItem('user-audience');
    axios({
      url: `${API_HOST}/custom/profiles/user`,
      method: 'POST',
      headers:
        headers && headers.jwt && headers.team
          ? {
              Authorization: `Bearer ${headers.jwt}`,
              'X-Mission-Control-Team': headers.team
            }
          : getAuthHeader(),
      data: { name, username, email, password, rePassword, profession, entityType, audience }
    })
      .then(response => {
        const user = logged();
        if (response.data.success)
          resolve({
            ...response.data.success,
            jwt: headers && headers.jwt ? headers.jwt : (user && user.jwt) || null
          });
        else {
          const error = new Error();
          error.type = response.data.error;
          reject(error);
        }
      })
      .catch(e => {
        reject(e);
      });
  });

export const getProfile = (token, team) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Mission-Control-Team': team
      }
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        resolve({});
      });
  });

export const checkUserSession = () => {
  const user = logged();
  if (!user) {
    return Promise.resolve(false);
  }
  return axios({
    url: `${API_HOST}/custom/profiles/user`,
    headers: {
      Authorization: `Bearer ${user.jwt}`,
      'X-Mission-Control-Team': user.team
    }
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Some social network like facebook needs extra reuest for retrieve email
 */
const retrieveEmail = ({ params, provider }) =>
  new Promise((resolve, reject) => {
    if (provider === 'google') return resolve(params['id_token[payload][email]']);
    if (provider === 'local') return resolve(params.email);
    // Default facebook retrieve email
    return axios({
      url: `https://graph.facebook.com/me?fields=email&access_token=${params.access_token}`
    })
      .then(response => {
        resolve(response.data.email);
      })
      .catch(() => {
        reject();
      });
  });

/**
 * Check if user email exist in api
 */
export const checkUser = ({ email }) =>
  new Promise((resolve, reject) => {
    axios({ url: `${API_HOST}/custom/teams/user/${email}` })
      .then(response => {
        resolve(response.data.provider);
      })
      .catch(() => {
        reject();
      });
  });

/**
 * Check user o email status
 */
const checkCredentials = ({ identifier }) =>
  new Promise((resolve, reject) => {
    checkUser({ email: identifier })
      .then(provider => {
        if (provider === false) reject(new Error(translate('error.not-found.user-or-email', 'en')));
        if (provider === 'local') reject(new Error(translate('error.incorrect.password', 'en')));
        else reject(new Error(translate(`error.mail.exist.provider.${provider}`, 'en')));
      })
      .catch(error => {
        Sentry.captureException(error);
        reject(new Error(translate('error.credentials.not-found', 'en')));
      });
  });

export const register = ({ provider, data }) =>
  new Promise((resolve, reject) => {
    const location = window.location.search;
    let config = {};
    if (provider !== 'local') config = { url: `${API_HOST}/auth/${provider}/callback${location}` };
    else
      config = {
        url: `${API_HOST}/auth/local/register`,
        method: 'POST',
        data,
        timeout: 30000
      };
    axios(config)
      .then(res => {
        const audience = localStorage.getItem('user-audience');
        gtmFireEvent({
          event: 'onSecurity',
          category: audience === 'video' ? 'video-maker' : 'widgets',
          action: `register-${audience}`,
          label: `${provider}-register-2`
        });
        gtmFireEvent({
          event: 'onSecurity',
          category: 'security',
          action: 'register',
          label: `${provider}-register-2`
        });
        resolve(res.data);
      })
      .catch(e => {
        Sentry.captureException(e);
        if (e.response) {
          const res = e.response ? e.response.data : {};
          if (
            isArray(res.message) &&
            isObject(res.message[0]) &&
            isArray(res.message[0].messages) &&
            isObject(res.message[0].messages[0])
          ) {
            gtmFireEvent({
              event: 'onSecurity',
              category: 'security',
              action: 'register-error',
              label: 'user email already exist'
            });
            if (res.message[0].messages[0].id === 'Auth.form.error.email.taken') {
              const params = provider !== 'local' ? query.parse(location) : { email: data.email };
              retrieveEmail({ provider, params })
                .then(email => checkUser({ email }))
                .then(check => {
                  reject(new Error(mailExistProviderError({ provider: check })));
                })
                .catch(() => {
                  reject(new Error(translate('mailExistError', 'en')));
                });
            } else if (res.message[0].messages[0].id === 'Auth.form.error.username.taken')
              reject(new Error(translate('usernameExistError', 'en')));
          } else if (res.message && res.message.message === 'Email was not available.') {
            reject(new Error(translate('error.credentials.mail-not-provided', 'en')));
          } else {
            gtmFireEvent({
              event: 'onSecurity',
              category: 'security',
              action: 'register-error',
              label: "Couldn't login app into api"
            });
            reject(new Error(translate('error.network', 'en')));
          }
        } else reject(new Error(translate('error.network', 'en')));
      });
  });

export const auth = ({ identifier, password }) =>
  new Promise((resolve, reject) => {
    axios({
      method: `POST`,
      url: `${API_HOST}/auth/local`,
      data: {
        identifier,
        password
      },
      timeout: 30000
    })
      .then(response => {
        return Promise.all([response.data, create(response.data)]);
      })
      .then(([response, team]) => {
        return Promise.all([
          response,
          team,
          getProfile(response.jwt, team._id),
          plans({
            headers: {
              Authorization: `Bearer ${response.jwt}`,
              'X-Mission-Control-Team': team._id
            }
          })
        ]);
      })
      .then(([response, team, extra, plan]) => {
        const user = {
          jwt: response.jwt,
          username: response.user.username,
          email: response.user.email,
          team: team._id,
          branding: (isArray(team.brandings) && team.brandings[0]) || null,
          profile: extra.profile,
          provider: extra.provider,
          confirmed: response.user.confirmed,
          _id: response.user._id,
          audience: response.user.audience
        };
        if (response.user && response.user.disabled) {
          const error = new Error(
            'Your account has been deleted by yourself. Click {message.here} to recover it'
          );
          error.user = user;
          return Promise.reject(error);
        }
        return Promise.all([user, plan]);
      })
      .then(([user, plan]) => {
        gtmFireEvent({
          event: 'onSecurity',
          category: 'security',
          action: 'login',
          label: 'User login'
        });

        resolve({ user, plan });
      })
      .catch(e => {
        Sentry.captureException(e);
        gtmFireEvent({
          event: 'onSecurity',
          category: 'security',
          action: 'login-error',
          label: 'User login error'
        });
        if (e.user) return reject(e);
        if (!e.response) return reject(new Error(translate('error.network', 'en')));
        return checkCredentials({ identifier }).catch(error => reject(error));
      });
  });

export const activate = ({ code }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/activate`,
      method: 'POST',
      data: { code }
    })
      .then(response => {
        if (response.data.success) resolve();
        else if (response.data.error) reject(new Error(translate(response.data.error)));
        else reject(new Error(translate('error.network')));
      })
      .catch(() => {
        reject(new Error(translate('error.network')));
      });
  });

export const disable = () =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/activate`,
      method: 'DELETE',
      headers: getAuthHeader()
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const verifyUserCode = ({ code }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/confirm`,
      method: 'POST',
      data: { code }
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject(new Error(translate(response.data.error)));
      })
      .catch(() => {
        reject(new Error(translate('error.network')));
      });
  });

export const sendCode = ({ redirectUrl }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/sendEmailConfirmation`,
      method: 'POST',
      headers: getAuthHeader(),
      data: { redirectUrl }
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject(new Error(translate(response.data.error || 'error.network')));
      })
      .catch(() => {
        reject(new Error(translate('error.network')));
      });
  });

export const sendRecoveryCode = ({ headers }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/sendRecoveryCode`,
      method: 'POST',
      headers
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject(new Error(translate(response.data.error || 'error.network')));
      })
      .catch(() => {
        reject(new Error(translate('error.network')));
      });
  });

export const forgotPassword = email =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/forgot-password`,
      method: 'POST',
      data: { email }
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject(new Error(translate(response.data.error || 'error.network')));
      })
      .catch(() => {
        reject(new Error(translate('error.network')));
      });
  });

export const resetPassword = ({ code, password, rePassword }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/reset-password`,
      method: 'POST',
      data: { code, password, rePassword }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else
          reject(
            new Error(
              translate(response.data.error || 'error.network').replace(
                'resend link',
                '{message.resend.link}'
              )
            )
          );
      })
      .catch(() => {
        reject(
          new Error(translate('error.network').replace('resend link', '{message.resend.link}'))
        );
      });
  });

export const isRoot = () =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/profiles/root`,
      headers: getAuthHeader(),
      method: 'POST'
    })
      .then(response => {
        if (response.data.success) resolve();
        else reject();
      })
      .catch(() => {
        reject();
      });
  });
