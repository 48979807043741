export default [
  {
    name: 'Angry',
    value: 'angry'
  },
  {
    name: 'Bright',
    value: 'bright'
  },
  {
    name: 'Calm',
    value: 'calm'
  },
  {
    name: 'Christmas',
    value: 'christmas'
  },
  {
    name: 'Dark',
    value: 'dark'
  },
  {
    name: 'Dramatic',
    value: 'dramatic'
  },
  {
    name: 'Epic',
    value: 'epic'
  },
  {
    name: 'Funky',
    value: 'funky'
  },
  {
    name: 'Funny',
    value: 'funny'
  },
  {
    name: 'Happy',
    value: 'happy'
  },
  {
    name: 'Inspirational',
    value: 'inspirational'
  },
  {
    name: 'Motivational',
    value: 'motivational'
  },
  {
    name: 'Romantic',
    value: 'romantic'
  },
  {
    name: 'Sad',
    value: 'sad'
  }
];
