import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Loader from '../Loader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
      maxHeight: '100%',
      padding: '24px 52px',
      overflow: ({ isLoading }) => (isLoading ? 'hidden' : ''),
      '@media only screen and (max-width: 425px)': {
        padding: '24px 12px'
      }
    }
  },
  close: {
    position: 'absolute',
    top: 3,
    right: 3,
    // color: '#3399FF',
    color: '#525252',
    '&:hover': {
      // color: '#fff',
      // backgroundColor: '#0060BF'
      color: '#444950',
      backgroundColor: '#E4E6EB'
    }
  },
  title: {
    position: 'absolute',
    fontSize: 24,
    top: 12
  },
  notification: {
    position: 'absolute',
    fontSize: 24,
    top: 12
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomDialog = ({
  forwardRef,
  isLoading,
  children,
  className,
  onEscapeKeyDown,
  useClose,
  title,
  maxWidth,
  onClose,
  classNameTitle,
  fullWidth,
  loaderBackgroundColor,
  loaderContent,
  Wrapper,
  wrapperProps
}) => {
  const classes = useStyles({ isLoading });
  const [open, setOpen] = React.useState(false);
  const modalRef = React.useRef(null);

  React.useImperativeHandle(forwardRef, () => ({
    show: () => {
      setOpen(true);
    },
    hide: () => {
      setOpen(false);
    }
  }));

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const content = () => (
    <>
      {isLoading && (loaderContent || <Loader backgroundColor={loaderBackgroundColor} />)}
      {title && <Box className={clsx(classes.title, classNameTitle, 'title')}>{title}</Box>}
      {children}
    </>
  );

  return (
    <Dialog
      open={open}
      className={`${classes.root} ${className}`}
      TransitionComponent={Transition}
      onClose={onEscapeKeyDown}
      ref={modalRef}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {useClose && (
        <Tooltip title="Close modal">
          <IconButton onClick={handleClose} className={`close-button ${classes.close}`}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {Wrapper && <Wrapper {...wrapperProps}>{content()}</Wrapper>}
      {!Wrapper && content()}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  forwardRef: PropTypes.objectOf(PropTypes.any),
  wrapperProps: PropTypes.objectOf(PropTypes.any),
  onEscapeKeyDown: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  useClose: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  classNameTitle: PropTypes.string,
  loaderBackgroundColor: PropTypes.string,
  loaderContent: PropTypes.node,
  Wrapper: PropTypes.func
};

CustomDialog.defaultProps = {
  forwardRef: {},
  wrapperProps: {},
  onEscapeKeyDown: () => {},
  className: '',
  children: null,
  useClose: false,
  isLoading: false,
  title: null,
  maxWidth: 'md',
  onClose: () => {},
  classNameTitle: null,
  fullWidth: false,
  loaderBackgroundColor: '#fff',
  loaderContent: null,
  Wrapper: null
};

export default CustomDialog;
