import { Container, Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const TextContainer = styled(Box)({
  marginTop: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .plan-text-desktop': {
    display: 'flex'
  },
  '& .plan-text-mobile': {
    display: 'none'
  },
  '@media only screen and (max-width: 1160px)': {
    flexDirection: 'column',
    '& .plan-text-desktop': {
      display: 'none'
    },
    '& .plan-text-mobile': {
      display: 'flex',
      marginTop: 21
    }
  }
});

const TextHeader = styled(Typography)({
  textAlign: 'center',
  fontSize: 28,
  fontWeight: 700,
  lineHeight: '38px',
  color: '#000',
  '@media only screen and (max-width: 425px)': {
    fontSize: 18,
    lineHeight: '32px'
  }
});

const SubText = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '36px',
  color: '#000',
  textAlign: 'center'
});

export function VideosPlanHeader() {
  return (
    <Container style={{ maxWidth: 1024 }}>
      <TextContainer>
        <TextHeader>Unlock WOXO</TextHeader>
      </TextContainer>
      <SubText>Automate video creation in ways you&apos;ve never imagined!</SubText>
    </Container>
  );
}
