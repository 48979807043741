import { Box, Typography, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/styles';

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#4584FA',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#4584FA',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #4584FA`,
    backgroundColor: '#4584FA',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {}
}))(Switch);

const Wrapper = styled(Box)({
  textAlign: 'center',
  marginTop: '32px',
  '& > * + *': {
    marginLeft: '16px'
  }
});

const Discount = styled(Typography)({
  color: '#414042',
  fontWeight: 500,
  fontSize: 14,
  marginTop: 10,
  transition: 'color .5s',
  '&[data-disabled]': {
    color: 'rgba(0, 0, 0, 0.38)'
  }
});

const BillingText = styled(Typography)(({}) => ({
  textTransform: 'none',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  fontSize: '16px',
  fontWeight: 600,
  color: '#414042',
  transition: 'color .5s',
  '&[data-disabled]': {
    color: 'rgba(0, 0, 0, 0.38)'
  }
}));

type BillingPeriod = 'year' | 'month';

interface Props {
  discount: string;
  billingPeriod: BillingPeriod;
  onChange: (billingPeriod: BillingPeriod) => void;
}

export function PlansSwitch({ discount, billingPeriod, onChange }: Props) {
  const checkedPeriod = billingPeriod === 'year';

  return (
    <Wrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        fontWeight={500}
      >
        <BillingText data-disabled={billingPeriod === 'year' ? true : undefined}>
          Monthly
        </BillingText>
        <StyledSwitch
          checked={checkedPeriod}
          onChange={event => {
            onChange(event.target.checked ? 'year' : 'month');
          }}
          name="checkedPeriod"
        />
        <BillingText data-disabled={billingPeriod === 'month' ? true : undefined}>
          Yearly
        </BillingText>
      </Box>
      <Discount data-disabled={billingPeriod === 'month' ? true : undefined}>
        Save up to {discount}
      </Discount>
    </Wrapper>
  );
}
