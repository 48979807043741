import { useCallback, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { useAuthentication } from './useAuthentication';
import { session } from '../services/stripe.services';
import { usePaymentsModal } from '../components/PaymentsModal';
import type { Plan } from '../components/PlanCard';

interface Options {
  layoutRef: any;
  landingPageLink?: string;
}

const usePayment = ({ layoutRef, landingPageLink: landingUrlParam }: Options) => {
  const router = useRouter();
  const { user } = useAuthentication();
  const paymentsModal = usePaymentsModal();
  const [loadingCard, setLoadingCard] = useState<false | string>(false);
  const landingPageLink = landingUrlParam || router.pathname;
  const isNotAuthenticated = !user;

  const processPayment = useCallback(
    (item: Plan) => {
      if (item.type === 'Free' || item.price === 0) {
        setLoadingCard(false);
        paymentsModal.close();
        Router.push(landingPageLink);
      } else {
        setLoadingCard(item.id);
        session({ price: item.id, category: item.category });
      }
    },
    [landingPageLink, paymentsModal]
  );

  return {
    handlePayment: useCallback(
      (item: Plan) => {
        if (isNotAuthenticated && layoutRef && layoutRef.current) {
          layoutRef.current.showSignUp(() => processPayment(item), { plan: true });
        } else {
          processPayment(item);
        }
      },
      [layoutRef, processPayment, isNotAuthenticated]
    ),
    loadingCard
  };
};

export default usePayment;
