import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import React from 'react';

const WrapperTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'var(--color-bg, #fff)',
    color: 'var(--color-font)',
    '&[data-shit]': {
      backgroundColor: 'teal'
    },
    fontSize: 13
  },
  arrow: {
    color: 'var(--color-bg, #fff)'
  }
}))(Tooltip);

export function StyledTooltip({
  style,
  ...props
}: Omit<TooltipProps, 'PopperProps'> & { style?: React.CSSProperties }) {
  return (
    <WrapperTooltip
      {...props}
      PopperProps={{
        popperRef: node => {
          if (node && style) {
            const element = node.popper as any;
            if (element.style) {
              for (let [key, value] of Object.entries(style)) {
                element.style.setProperty(key, value);
              }
            }
          }
        }
      }}
    />
  );
}
