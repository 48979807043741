export function UnlimitedIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="156px"
      viewBox="0 0 156.1 67.2"
      style={{ margin: '0 24px' }}
      xmlSpace="preserve"
    >
      <linearGradient
        id="gradient"
        gradientUnits="userSpaceOnUse"
        x1="-556.4082"
        y1="429.0671"
        x2="-557.4082"
        y2="429.0671"
        gradientTransform="matrix(156.1901 0 0 -67.183 87061.6094 28859.625)"
      >
        <stop offset="0" style={{ stopColor: '#5BC005' }} />
        <stop offset="1" style={{ stopColor: '#CDD40F' }} />
      </linearGradient>
      <path
        className="st0"
        style={{ fill: 'url(#gradient)' }}
        d="M122.6,67.2L122.6,67.2c-9,0-17.5-3.5-23.7-9.9L84.8,43.2l2.8-2.8l14.1,14.1
c5.5,5.6,13,8.7,20.9,8.7h0.1c16.3,0,29.5-13.2,29.5-29.5c0-16.3-13.2-29.5-29.5-29.5l0,0h-0.1c-7.8,0-15.3,3.1-20.8,8.6L57.2,57.2
C44,70.3,22.8,70.3,9.8,57.2C-3.3,44.1-3.3,22.8,9.8,9.8C16.1,3.5,24.6,0,33.5,0h0.1C42.5,0,51,3.5,57.2,9.9L71,23.7l-2.8,2.8
L54.4,12.7C48.9,7.1,41.4,4,33.6,4h-0.1C17.2,4,4,17.2,4,33.5C4,49.8,17.2,63,33.5,63c7.8,0,15.3-3.1,20.8-8.6L99,9.9
c6.3-6.3,14.8-9.8,23.7-9.8h0.1c18.5,0,33.5,15,33.5,33.5S141.1,67.2,122.6,67.2L122.6,67.2L122.6,67.2z"
      />
    </svg>
  );
}
