import type { PlanType } from '.';

export const plansColors: Record<
  PlanType,
  { primary: string; accent: string; hover: string; font: string; link?: string }
> = {
  Free: { primary: '#ff187e', accent: '#ff187e', hover: '#d6377c', font: '#fff' },
  Basic: { primary: '#7C11FF', accent: '#e62e2d', hover: '#5010a0', font: '#fff' },
  Pro: {
    primary: '#F5D400',
    accent: '#e62e2d',
    hover: '#ccb003',
    font: '#414042',
    link: '#0872b7'
  },
  Business: { primary: '#5BC005', accent: '#f5d300', hover: '#3b7f01', font: '#fff' }
};
