import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import type { PlanCategory } from '.';

interface Props {
  onChange: (category: PlanCategory) => void;
  category: string;
}

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20,
  '& .MuiToggleButton-root': {
    borderRadius: 45,
    background: '#F5F5F5',
    color: '#414042',
    textTransform: 'none',
    width: '140px!important',
    transition: 'all .5s',
    '@media only screen and (max-width: 960px)': {
      width: '120px!important'
    }
  },
  '& .MuiToggleButton-root:hover': {
    backgroundColor: '#EEEEEE'
  },
  '& .Mui-selected': {
    background: '#4686FF',
    color: '#fff'
  },
  '& .Mui-selected:hover': {
    background: '#0060BF'
  },
  '& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
});

export function PlansToggle({ onChange, category }: Props) {
  const tabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
  return (
    <Container>
      <ToggleButtonGroup
        size={tabletOrMobile ? 'small' : 'medium'}
        value={category}
        exclusive
        onChange={(_, newCategory) => onChange(newCategory)}
        aria-label="Plans"
      >
        <ToggleButton value="video" aria-label="Video Maker">
          Video Maker
        </ToggleButton>
        <ToggleButton value="widget" aria-label="Social Widgets">
          Social Widgets
        </ToggleButton>
      </ToggleButtonGroup>
    </Container>
  );
}
