export default [
  'Roboto',
  'Roboto Condensed Bold',
  'Open sans',
  'Open Sans Condensed',
  'Patua One',
  'Abril Fatface',
  'Alfa Slab One',
  'Amatic SC',
  'Amatic SC Bold',
  'Anton',
  'Bangers',
  'Bebas Neue',
  'Cabin Sketch',
  'Caveat Brush',
  'Creepster',
  'Crete Round',
  'Fjalla One',
  'Fredericka the Great',
  'Gentium Basic',
  'Griffy',
  'Lato',
  'Libre Baskerville',
  'Lobster',
  'Love Ya Like A Sister',
  'Montserrat',
  'Monoton',
  'Merriweather',
  'Noto Sans',
  'PT Sans Narrow',
  'Poppins',
  'Pacifico',
  'Raleway',
  'Rubik One',
  'Shrikhand',
  'Squada One',
  'Source Sans Pro',
  'Titillium Web',
  'Varela Round',
  'Yellowtail',
  'Akaya Telivigala'
];
